import { useState, useEffect } from 'react';
import './App.css';
import { getAddress, sendBtcTransaction} from 'sats-connect';

function App() {
  const [address, setAddress] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [showWalletSelector, setShowWalletSelector] = useState(false);
  const [isInscribing, setIsInscribing] = useState(false);

  const PARENT_INSCRIPTION = "b1c5baa2593b256068635bbc475e0cc439d66c2dcf12e9de6f3aaeaf96ff818bi0";

  useEffect(() => {
    const loadSatsConnect = async () => {
      if (typeof window.SatsConnect === 'undefined') {
        const script = document.createElement('script');
        script.src = 'https://sats-connect.com/lib/sats-connect.js';
        script.async = true;
        document.body.appendChild(script);
      }
    };
    loadSatsConnect();
  }, []);

  const connectUnisat = async () => {
    try {
      if (typeof window.unisat !== 'undefined') {
        const accounts = await window.unisat.requestAccounts();
        setAddress(accounts[0]);
        setWallet('unisat');
      } else {
        alert('Unisat wallet is not installed. Please install it from https://unisat.io/');
      }
    } catch (error) {
      console.error('Error connecting Unisat wallet:', error);
    }
  };

  const connectXverse = async () => {
    const getAddressOptions = {
      payload: {
        purposes: ['ordinals', 'payment'],
        message: 'Address for receiving payments',
        network: {
          type: 'Mainnet'
        },
      },
      onFinish: (response) => {
        setAddress(response.addresses[0].address);
        setWallet('xverse');
      },
      onCancel: () => alert('Xverse connection request canceled'),
    };

    try {
      await getAddress(getAddressOptions);
    } catch (error) {
      console.error('Error connecting to Xverse wallet:', error);
      alert('Failed to connect to Xverse wallet. Make sure it\'s installed and try again.');
    }
  };

  const disconnectWallet = () => {
    setAddress(null);
    setWallet(null);
  };

  const getOrderDetails = async (orderId) => {
    const response = await fetch(`https://api.ordinalsbot.com/order?id=${orderId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch order details');
    }
    return await response.json();
  };

  const handlePayment = async (orderId) => {
    try {
      const orderDetails = await getOrderDetails(orderId);
      console.log('Order details:', orderDetails);

      if (orderDetails.charge && orderDetails.charge.amount) {
        const amount = Number(orderDetails.charge.amount);
        const paymentAddress = orderDetails.charge.address;

        console.log('Payment address:', paymentAddress);
        console.log('Amount:', amount);

        if (wallet === 'unisat') {
          try {
            const txid = await window.unisat.sendBitcoin(paymentAddress, amount);
            console.log('Payment sent via Unisat, transaction ID:', txid);
            alert(`Payment of ${amount} satoshis sent via Unisat. Transaction ID: ${txid}`);
          } catch (error) {
            console.error('Unisat payment error:', error);
            alert(`Error sending payment via Unisat: ${error.message}`);
          }
        } else if (wallet === 'xverse') {
          try {
            const sendBtcOptions = {
              payload: {
                network: {
                  type: 'Mainnet'
                },
                recipients: [
                  {
                    address: paymentAddress,
                    amountSats: parseInt(amount, 10) // Ensure amount is an integer
                  }
                ],
                // Remove senderAddress as it might not be needed
              },
              onFinish: (response) => {
                console.log('Payment sent via Xverse, transaction ID:', response.txId);
                alert(`Payment of ${amount} satoshis sent via Xverse. Transaction ID: ${response.txId}`);
              },
              onCancel: () => alert('Xverse payment cancelled')
            };
        
            console.log('Sending BTC transaction with options:', JSON.stringify(sendBtcOptions, null, 2));
            await sendBtcTransaction(sendBtcOptions);
        
          } catch (error) {
            console.error('Xverse payment error:', error);
            console.error('Error details:', error.message, error.stack);
            alert(`Error sending payment via Xverse: ${error.message}`);
          }
        } else {
          alert(`Please send ${amount} satoshis to ${paymentAddress}`);
        }

        await checkPaymentStatus(orderId);
      } 
    } catch (error) {
      console.error('Error handling payment:', error);
      alert('Error processing payment. Please try again.');
    }
  };

  const checkPaymentStatus = async (orderId) => {
    let isPaid = false;
    while (!isPaid) {
      try {
        const orderDetails = await getOrderDetails(orderId);

        if (orderDetails.paid) {
          alert('Payment received! Your inscription is being processed.');
          isPaid = true;
        } else if (orderDetails.expired) {
          alert('Order expired. Please create a new order.');
          break;
        } else {
          await new Promise(resolve => setTimeout(resolve, 5000));
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
        break;
      }
    }
  };

  const mintInscription = async () => {
    if (!address || !wallet) {
      alert("Please connect a wallet first.");
      return;
    }
    setIsInscribing(true);

    try {
      const response = await fetch('https://api.ordinalsbot.com/inscribe', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delegates: [
            {
              delegateId: PARENT_INSCRIPTION
            }
          ],
          receiveAddress: address,
          lowPostage: true,
          fee: 15
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create inscription order');
      }

      const data = await response.json();
      console.log('Order created:', data);

      // Handle payment using the new payment system
      await handlePayment(data.id);

    } catch (error) {
      console.error('Minting failed:', error);
      alert(`Minting failed: ${error.message}`);
    } finally {
      setIsInscribing(false);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
      <img 
          src="https://ordinals.com/content/cfab194b924f7785c6e453728e1c264b89b74843633278cda3ad3f57576c1e93i0" 
          className="App-logo-small" 
          alt="Ordinal" 
        />
        {!address ? (
          <>
            <div className="wallet-buttons">
              <div className="wallet-selector">
                <button 
                  className="wallet-button"
                  onClick={() => setShowWalletSelector(!showWalletSelector)}
                >
                  Connect Wallet
                </button>
                {showWalletSelector && (
                  <div className="wallet-dropdown">
                    <button onClick={() => {
                      connectUnisat();
                      setShowWalletSelector(false);
                    }}>
                      Unisat Wallet
                    </button>
                    <button onClick={() => {
                      connectXverse();
                      setShowWalletSelector(false);
                    }}>
                      Xverse Wallet
                    </button>
                  </div>
                )}
              </div>
            </div>
            <p>
              Let's create the Minting website of the future!
            </p>
            <br />
            <br />
            <a
              className="App-link"
              href="https://ordiscan.com/inscriptions?types=&sort=oldest"
              target="_blank"
              rel="noopener noreferrer"
            >
              Study INSCRIPTIONS
            </a>
          </>
        ) : (
          <>
            <div className="wallet-buttons">
              <div className="wallet-info">
                <span>
                  {wallet === 'unisat' ? 'Unisat: ' : 'Xverse: '}
                  {address.slice(0,9)}...{address.slice(-4)}
                </span>
                <button 
                  className="disconnect-button"
                  onClick={disconnectWallet}
                >
                  Disconnect
                </button>
              </div>
            </div>
            <button 
              className="wallet-button mint-button"
              onClick={mintInscription}
              disabled={isInscribing}
            >
              {isInscribing ? 'MINTING...' : 'MINT NOW'}
            </button>
            <div className="wizard-content">
              <img 
                src="https://ordinals.com/content/b1c5baa2593b256068635bbc475e0cc439d66c2dcf12e9de6f3aaeaf96ff818bi0"
                alt="Magic Internet Money Wizard"
                className="wizard-image"
              />
              <p className="wizard-text">
                Magic internet money is the most famous meme in Bitcoin ecosystem. 
                We can remember the OG IRC channel <a 
                  href="https://gnusha.org/bitcoin-wizards/"
                  target="_blank"
                  rel="noopener noreferrer"
                >#bitcoin-wizard</a>. 
                It's a movement! Be a part by minting your!
              </p>
            </div>
          </>
        )}
      </header>
    </div>
  );
}

export default App;

